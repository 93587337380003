/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/self-closing-comp */
/* eslint-disable react/no-array-index-key */
import React, { lazy, Suspense, useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NonAuthRoutes } from "./constants";
// eslint-disable-next-line import/order, import/no-unresolved

const Home = lazy(() => import("./components/home"));
const AboutUs = lazy(() => import("./components/about-us"));
const ContactUs = lazy(() => import("./components/contact-us"));
const QuickReceipt = lazy(() => import("./components/swift-receipts"));
const SabiBookkeeping = lazy(() => import("./components/sabi-bookkeeping"));
const SabiFinancing = lazy(() => import("./components/sabi-financing"));
const Faq = lazy(() => import("./components/faq/faq"));
const SwiftMoneyComponent = lazy(() => import("./components/swift-money"));
const PaymentCallback = lazy(() => import("./components/payment-callback"));

function App() {
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        if (isLoading) {
            return setIsLoading(false);
        }
    }, []);
    const location = useLocation();
    return (
        <>
            {isLoading ? (
                <div className="w-full h-screen flex justify-center items-center">
                    <div
                        style={{ borderTopColor: "transparent" }}
                        className="w-16 h-16 border-4 border-blue-200 border-solid rounded-full animate-spin"
                    ></div>
                </div>
            ) : (
                <AnimatePresence>
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                    <Suspense fallback={<div />}>
                        <Routes location={location} key={location.pathname}>
                            <Route index element={<Home />} />
                            <Route
                                path={NonAuthRoutes.aboutUs}
                                element={<AboutUs />}
                            />
                            <Route
                                path={NonAuthRoutes.contact}
                                element={<ContactUs />}
                            />{" "}
                            <Route
                                path={NonAuthRoutes.quickReceipt}
                                element={<QuickReceipt />}
                            />{" "}
                            <Route
                                path={NonAuthRoutes.sabiBookkeeping}
                                element={<SabiBookkeeping />}
                            />{" "}
                            <Route
                                path={NonAuthRoutes.sabiFinancing}
                                element={<SabiFinancing />}
                            />{" "}
                            <Route
                                path={NonAuthRoutes.swiftMoney}
                                element={<SwiftMoneyComponent />}
                            />{" "}
                            <Route
                                path={NonAuthRoutes.paymentCallback}
                                element={<PaymentCallback />}
                            />{" "}
                            <Route path={NonAuthRoutes.faq} element={<Faq />} />
                        </Routes>
                    </Suspense>
                </AnimatePresence>
            )}
        </>
    );
}

export default App;
