import React from "react";
import ReactDom from "react-dom";
import { HelmetProvider } from "react-helmet-async";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
    ReactDom.hydrate(
        <HelmetProvider>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </HelmetProvider>,
        rootElement
    );
} else {
    ReactDom.render(
        <HelmetProvider>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </HelmetProvider>,
        rootElement
    );
}
// ReactDOM.render(
//     <React.StrictMode>
//         <HelmetProvider>
//             <BrowserRouter>
//                 <App />
//             </BrowserRouter>
//         </HelmetProvider>
//     </React.StrictMode>,
//     document.getElementById("root")
// );

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
