/* eslint-disable import/prefer-default-export */

export const NonAuthRoutes = {
    home: "/",
    aboutUs: "/about-us",
    quickReceipt: "/swift-receipt",
    sabiBookkeeping: "/sabi-bookkeeping",
    learn: "/learn",
    sabiFinancing: "/pastel-financing",
    swiftMoney: "/swift-money",
    contact: "/contact-us",
    faq: "/faq",
    paymentCallback: "/payment/successful",
};
